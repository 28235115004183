
/**
 * DTC Media - Occasionmodule
 */

(function($) {

    // Get the dimensions of an element, even if it it hidden.
    $.getDimensions = function (element, natural) {
        var $element = $(element);
        var display = $element.css('display');

        if (display && display !== 'none') {
            var dimensions = {
                width: element.offsetWidth,
                height: element.offsetHeight,
                innerHeight: $element.height(),
                innerWidth: $element.width()
            };
        } else {
            var originalStyles = {
                visibility: element.style.visibility,
                position: element.style.position,
                display: element.style.display,
                height: element.style.height
            };

            var tmpStyles = {
                visibility: 'hidden',
                display: 'block'
            };


            if (natural) {
                tmpStyles.height = '';
            }

            if (originalStyles.position !== 'fixed') {
                tmpStyles.position = 'absolute';
            }

            $element.css(tmpStyles);

            dimensions = {
                width: element.offsetWidth,
                height: element.offsetHeight,
                innerHeight: $element.height(),
                innerWidth: $element.width()
            };

            $element.css(originalStyles);
        }

        return dimensions;
    }

    if ($(window).width() <= 480) {
        $('.list-album-thumbs li').click(function(){
            $('html, body').animate({
                scrollTop: $('.slider-album').offset().top
            }, 700);
        });
    }

    // Zoekfilters openen
    $('.toggle-search').click(function() {
        $('aside#filters').slideToggle();
        $(this).toggleClass('close open');
    });

	// Uitgebreide zoekfilters openen
	$("#uitgebreid-zoeken").click(function() {
        $(this).find('.fa').toggleClass('fa-caret-down fa-close');
		if($(this).parent('section').hasClass('closed')) {
			$(this).parent('section').toggleClass('closed opened');
			$(".uitgebreid-zoeken .inner").slideToggle();
		} else {
			$(this).parent('section').toggleClass('closed opened');
			$(".uitgebreid-zoeken .inner").slideToggle();
		}
	});

	// Switch view
	$("#gridview").click(function(e) {
		e.preventDefault();
		if ( !$(".list-offers").hasClass("grid") )
		{
			$(".list-offers").addClass('grid');
		}

		if ( !$(this).hasClass('active') )
		{
			$(this).addClass('active');
		}

		$("#listview").removeClass('active');
	});

	$("#listview").click(function(e) {
		e.preventDefault();
		if ( $(".list-offers").hasClass("grid") )
		{
			$(".list-offers").removeClass('grid');
		}	

		if ( !$(this).hasClass('active') )
		{
			$(this).addClass('active');
		}

		$("#gridview").removeClass('active');
	});

})(jQuery);